html,
body {
  height: 100%;
}

body {
  margin: 0;
}

app-root:has(.app-container-mobile) {
  app-activity-feed {
    .alert-buttons-container button {
      min-width: 32px;
      padding: 0;
    }
  }

  .hide-on-mobile {
    display: none;
  }
}
