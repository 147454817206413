.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.u-text--black {
  color: #292929;
}

.u-text--gray {
  color: #5B6770;
}

.u-text--blue {
  color: #0075B4;
}

.u-margin__left--extra-tiny {
  margin-left: 3px;
}

.u-margin__right--extra-tiny {
  margin-right: 3px;
}

.u-margin__right--tiny {
  margin-right: 6px;
}

.u-margin__right--normal {
  margin-right: 18px;
}

.u-margin__right--small {
  margin-right: 12px;
}

.u-margin__top--tiny {
  margin-top: 6px;
}

.u-margin__top--normal {
  margin-top: 18px;
}

.u-margin__bottom--none {
  margin-bottom: 0;
}

.u-margin__bottom--tiny {
  margin-bottom: 6px;
}

.u-margin__bottom--normal {
  margin-bottom: 18px;
}

.u-margin__bottom--medium {
  margin-bottom: 24px;
}

.u-margin__bottom--large {
  margin-bottom: 36px;
}

.u-margin__top--medium {
  margin-top: 24px;
}

.u-margin__top--large {
  margin-top: 36px;
}