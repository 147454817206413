$zonar-spacing: (
    none: 0,
    extra-tiny: 3px,
    tiny: 6px,
    small: 12px,
    normal: 18px,
    medium: 24px,
    large: 36px,
    xlarge: 48px,
    xxlarge: 72px
);


@mixin zonar-padding($size, $direction: null) {
    $size-value: if($size ==0, 0, map-get($zonar-spacing, $size));

    @if ($direction ==null) {
        padding: $size-value;
    }

    @else {
        padding-#{$direction}: $size-value;
    }
}


@mixin zonar-margin($size, $direction: null) {
    $size-value: if($size ==0, 0, map-get($zonar-spacing, $size));

    @if ($direction ==null) {
        margin: $size-value;
    }

    @else {
        margin-#{$direction}: $size-value;
    }
}