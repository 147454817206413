@use '@angular/material' as mat;
// pattern library
@import '@zonar-ui/material/zonar-ui-material.theme.scss';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/core/zonar-ui-core.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';
@import '@zonar-ui/activity-feed/zonar-ui-activity-feed.theme';

// material icon scss
@import 'material-icons/iconfont/material-icons.scss';

// application themes

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include zonar-ui-activity-feed-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: white;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

.content-container {
  padding: 2rem;
  overflow-y: hidden;
}

.app-container-desktop {
  .mat-drawer {
    .mat-drawer-inner-container {
      overflow: hidden; // This prevents the leftnav from scrolling
    }
  }
}

zui-sidenav-header {
  mat-toolbar {
    background: white !important;
    color: black !important;

    mat-icon {
      color: black !important;
    }
  }
}

.tab-width {
  max-width: 800px;
}
